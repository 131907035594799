// modify antd style 
.custome-form {
    .ant-form-item-explain-error {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .ant-picker, .ant-input-number {
        width: 100%;
    }
}