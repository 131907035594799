/* src/styles/main.css */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: Arial, sans-serif;
}
html, body, #root {
	height: 100%;
}

#root {
	min-width: 750px;
}

aside {
	width: 200px;
	background-color: #f4f4f4;
	padding: 1rem;
}

.login-page {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;

	.login-form {
		width: 360px;
	}

	h2 {
		margin-bottom: 24px;
	}
}