.property-page {
	display: block;
	height: calc(100vh - 64px); /* Full viewport height */

	/* Ensure the content below the navbar starts after it */
	.content-container {
		margin-top: 0; /* Space for the navbar height */
		padding: 0;
		display: flex;
		align-items: stretch;
		width: 100%; /* Full width */
		height: 100%; /* Full height minus the navbar height */
		max-width: 4000px;
	}

	/* Vertical navigation styling */
	.vertical-nav {
		width: 160px; /* Fixed width for the sidebar */
		background-color: #f4f4f4; /* Light background for the sidebar */
		border-right: 1px solid #ddd; /* Border for separation */
		padding: 20px; /* Add padding inside the sidebar */
		box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1); /* Optional shadow for separation */
		display: block;
		height: auto; /* Full height of the content container */
	}

	/* Ensure no margin or padding causes extra gaps in the sidebar */
	.vertical-nav h3 {
		margin-top: 0;
		margin-bottom: 10px; /* Add margin below the header */
	}

	.vertical-nav button {
		background-color: #007bff;
		color: white;
		border: none;
		padding: 10px 15px;
		margin-bottom: 10px; /* Add space between buttons */
		border-radius: 5px;
		cursor: pointer;
		text-align: left; /* Align text to the left */
		width: 100%; /* Full width of the sidebar */
	}

	.vertical-nav button.active {
		background-color: #0056b3; /* Darker color for active button */
	}

	.vertical-nav button:hover {
		background-color: #0056b3; /* Hover effect */
	}

	/* Main content area styling */
	.main-content {
		flex: 1; /* Allow the content to take up remaining space */
		background-color: #f9f9f9;
		padding: 20px;
		border-radius: 5px;
		overflow-y: auto; /* Allow scrolling if content exceeds the container height */
	}
}
