.submit-modal {
	.generic-submit-form {
		max-height: 65vh;
		padding: 0 8px;
	}

	.ant-modal-body {
		overflow: auto;
	}

	.work-history-wrap {
		padding: 8px;
		border: 1px dashed #ddd;
		margin-bottom: 10px;
	}
	.ant-input-number-affix-wrapper {
		width: 100%;
	}

	.top-form {
		display: flex;
		.top-form-item {
			width: 60%;
			&:nth-child(2) {
				width: 40%;
			}
		}
	}

	.select-box {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 16px;
		span {
			color: #1677ff;
		}
	}
}

.top-form-table {
	width: 100%;
	.ant-form-item {
		margin: 0;
	}
	td {
		border: 1px solid #ddd;
		padding: 4px 6px;
	}
	td:nth-child(1) {
		width: 35%;
	}
	td:nth-child(2) {
		width: 35%;
	}
	td:nth-child(3) {
		width: 30%;
	}
}
