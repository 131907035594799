.generic-table-container {
	background-color: #f4f7f6;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	min-width: 1660px;
}

.generic-table-container .high-light-row {
	background: #fadb14;
}

.generic-table-container .high-light-row .ant-table-cell {
	background: #fadb14;
}

.generic-table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;
}

.generic-table thead {
	background-color: #007bff;
	color: white;
}

.generic-table th,
.generic-table td {
	border: 1px solid #dee2e6;
	padding: 12px;
	text-align: left;
}

.generic-table th {
	background-color: #0056b3;
}

.generic-table tr:nth-child(even) {
	background-color: #f9f9f9;
}

.generic-table tr:hover {
	background-color: #e9ecef;
}

.pagination-controls {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.pagination-controls button {
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	background-color: #007bff;
	color: white;
	cursor: pointer;
	font-size: 16px;
}

.pagination-controls button:disabled {
	background-color: #c0c0c0;
	cursor: not-allowed;
}

.pagination-controls span {
	font-size: 16px;
}

.table-controls {
	display: flex;
	margin-bottom: 20px;
}

.create-button,
.download-button {
	/* padding: 10px 20px;
	border: none;
	border-radius: 5px;
	background-color: #007bff;
	color: white;
	cursor: pointer;
	font-size: 16px; */
	margin-right: 20px;
}

.create-button:hover,
.download-button:hover {
	/* background-color: #0056b3; */
}

.form-container {
	margin-top: 20px;
	padding: 20px;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-container input,
.form-container .react-select__control {
	display: block;
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 5px;
	width: 100%;
}

.form-container button {
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	background-color: #007bff;
	color: white;
	cursor: pointer;
	font-size: 16px;
	margin-right: 10px;
}

.form-container button:hover {
	background-color: #0056b3;
}

/* Error styles */
.error {
	border-color: red;
}

.error-message {
	color: red;
	font-size: 12px;
	margin-top: -8px;
	margin-bottom: 8px;
}

/* Styles for react-select */
.react-select__control {
	border: 1px solid #dee2e6;
	border-radius: 5px;
	padding: 0;
}

.react-select__menu {
	border-radius: 5px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.react-select__option {
	padding: 10px;
}

.react-select__option--is-selected {
	background-color: #007bff;
	color: white;
}

.react-select__option--is-focused {
	background-color: #e9ecef;
}
