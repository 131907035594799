/* General page styling */
.company-page {
	display: flex;
	flex-direction: column; /* Stack children vertically */
	min-height: calc(100vh - 64px); /* Full viewport height */

	/* Adjust the content container to account for the fixed navbar */
	.content-container {
		margin-top: 60px; /* Adjust based on the height of your navbar */
		width: 100%; /* Full width */
		flex-grow: 1; /* Allow this container to grow and fill the remaining space */
		display: flex; /* Enable flexbox layout */
		flex-direction: column; /* Stack content vertically within the container */
		align-items: center; /* Center content horizontally */
		padding: 20px;
	}

	/* Title styling */
	.content-container h1 {
		margin-bottom: 32px; /* Space below the title */
		font-size: 24px; /* Adjust font size */
		text-align: center; /* Center text horizontally */
	}

	/* Button styling for the overview button */
	.overview-button {
		margin: 0 auto 20px; /* Space below the button */
		padding: 10px 20px;
		background-color: #007bff;
		color: white;
		border: none;
		border-radius: 5px;
		cursor: pointer;
		font-size: 16px; /* Adjust font size */
		display: block; /* Ensure the button is displayed as a block for vertical stacking */
	}

	.overview-button:hover {
		background-color: #0056b3;
	}

	/* Container for overview button and property list */
	.button-list-container {
		width: 100%;
	}

	.ant-card {
		transition: all 0.2s ease-in-out;
		cursor: pointer;
		margin-bottom: 12px !important;
		&:hover {
			transform: translateY(-16px);
		}
	}
}
