.custome-range-number {
    display: flex;
    align-items: center;
    &-input {
        flex: auto;
    }
    .split-line {
        flex: none;
        font-size: 20px;
        margin: 0 4px;
    }
}